<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Actualizar estados de comercios</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
        <v-select
          v-model="selectedState"
          :items="states"
          prepend-icon="fa-filter"
          label="Seleccionar estado"
          item-text="name"
          item-value="value"
          aria-autocomplete="false"
          class="mt-10 mb-4"
          autocomplete="off"
          hide-details
          style="max-width: 95% !important"
          filled
          rounded
          :allow-overflow="false"
          clearable
        >
        </v-select>
      </v-card-title>
      <v-card-text class="height-modal">
        <a-transfer
          v-if="filteredBusinesses"
          :listStyle="{
            height: '400px!important',
             width: '40%',
            background: 'white',
          }"
          :data-source="filteredBusinesses"
          :titles="[
            'Opción inactiva',
            `Comercios ${
              selectedState == 'isOpen' ? 'Abiertos' : 'Destacados'
            }`,
          ]"
          :target-keys="businessesWithTrueStatus"
          :selected-keys="selectedKeys"
          :show-search="true"
          @search="handleSearch"
          :render="(item) => item.title"
          :disabled="disabled"
          :filter-option="filterOption"
          @change="handleChange"
          @selectChange="handleSelectChange"
          @scroll="handleScroll"
          :locale="{
            itemUnit: '',
            itemsUnit: '',
            notFoundContent: 'Lista vacía',
            searchPlaceholder: 'Buscar sección',
          }"
        />
      </v-card-text>
      <v-card-actions style="justify-content: center">
        <v-btn
          color="primary"
          :loading="saving"
          style="width: 90%"
          @click="updateBusinesses"
          height="50px"
          class="mb-5"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";
import transfer from "ant-design-vue/lib/transfer";

export default {
  name: "Sort",
  props: ["businesses"],
  components: {
    lottie: Lottie,
    "a-transfer": transfer,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      snackbar: false,
      snackbarText: "",
      saving: false,
      loading: true,
      disabled: false,
      states: [
        { name: "Destacados", value: "featured" },
        // {name : 'Abiertos', value: 'isOpen'}
      ],
      selectedState: "featured",
      filteredBusinesses: [],
      businessesWithTrueStatus: [],
      selectedKeys: [],
    };
  },
  computed: {
    ...mapState(["selectedBusiness"]),
  },

  watch: {
    selectedState(data) {
      this.businessesWithTrueStatus = this.businesses
        .filter((item) => item[this.selectedState])
        .map((data) => {
          return data[".key"];
        });

      this.filteredBusinesses = this.businesses.map((data) => {
        return {
          key: data[".key"],
          title: data.shortName,
        };
      });
    },
  },
  async mounted() {
    if (this.selectedState) {
      this.businessesWithTrueStatus = this.businesses
        .filter((item) => item[this.selectedState])
        .map((data) => {
          return data[".key"];
        });

      this.filteredBusinesses = this.businesses.map((data) => {
        return {
          key: data[".key"],
          title: data.shortName,
        };
      });
    }
  },

  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    handleChange(nextTargetKeys, direction, moveKeys) {
      this.businessesWithTrueStatus = nextTargetKeys;
    },
    handleSearch(dir, value) {
      return value.toUpperCase();
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
    filterOption(inputValue, option) {
      return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    },
    handleScroll(direction, e) {},
    changeBusinessStatus(businessId, status) {
      return new Promise((resolve, reject) => {
        let data = {};
        switch (this.selectedState) {
          case "featured":
            data = { featured: status };
            break;

          // case 'isOpen':
          //     data = { isOpen : status }
          // break;

          default:
            break;
        }

        db.collection("businesses")
          .doc(businessId)
          .update(data)
          .then((res) => {
            resolve("success");
          });
      });
    },
    async updateBusinesses() {
      this.saving = true;
      let businessesWithFalseStatus = this.businesses.filter(
        (item) => item[this.selectedState]
      ); // update status to false
      businessesWithFalseStatus = businessesWithFalseStatus.filter(
        (item) => !this.businessesWithTrueStatus.includes(item[".key"])
      );

      for (let i = 0; i < businessesWithFalseStatus.length; i++) {
        let business = businessesWithFalseStatus[i];
        await this.changeBusinessStatus(business[".key"], false);
      }

      for (let i = 0; i < this.businessesWithTrueStatus.length; i++) {
        await this.changeBusinessStatus(this.businessesWithTrueStatus[i], true);
      }
      this.saving = false;
      this.snackbarText = "Comercios actualizados correctamente";
      this.snackbar = true;
    },
  },
};
</script>

<style scoped>
.ant-transfer{
  /* height: 55vh;
        max-height: 55vh;
        min-height: 55vh; */
  overflow-y: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.list-group-item {
  border-bottom: 1px solid rgba(128, 128, 128, 0.425);
}

.ghost {
  opacity: 0.5;
  background: #e0b19d;
}

.flip-list-move {
  transition: transform 0.5s;
}
</style>
