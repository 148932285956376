<template>
  <v-card>
    <v-snackbar :color="snackbarColor" top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay
      style="z-index: 9999 !important"
      class="loading-center"
      :value="saving"
    >
      <lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>
    <v-card-title class="grid-close">
      <span class="headline">
        {{ operationType == "new" ? "Nuevo" : "Clonar" }} comercio</span
      >
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container fluid class="fill-height">
        <v-row class="pa-0 ma-0">
          <v-col cols="12" sm="12" md="12">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-img
                  v-on="on"
                  :src="imgSrcBanner || business.cover.original"
                  aspect-ratio="1"
                  class="grey lighten-2 cover-up"
                  max-width="100%"
                  max-height="250"
                  @click="selectImage('banner')"
                >
                  <v-row class="fill-height ma-0" align="end" justify="center">
                    <v-chip style="width: 20%" class="mb-5 img-tag">
                      Cover
                    </v-chip>
                  </v-row>
                </v-img>
              </template>
              <span>Cambiar imágen de cover</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-img
                  v-on="on"
                  :src="imgSrcBrand || business.logo.original"
                  aspect-ratio="1"
                  class="grey lighten-2 logo-up"
                  max-width="150"
                  max-height="150"
                  @click="selectImage('brand')"
                >
                  <v-row class="fill-height ma-0" align="end" justify="center">
                    <v-chip class="mb-1 img-tag">Logo</v-chip>
                  </v-row>
                </v-img>
              </template>
              <span>Cambiar imágen de logo</span>
            </v-tooltip>

            <v-form ref="form">
              <v-container fluid class="fill-height" ref="top">
                <v-col cols="12" md="12">
                  <v-text-field
                    rounded
                    outlined
                    type="text"
                    :rules="[rules.required]"
                    label="Nombre del comercio"
                    v-model="business.shortName"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    rounded
                    outlined
                    label="RTN del comercio"
                    v-model="business.tin"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    rounded
                    outlined
                    type="text"
                    :rules="[rules.required]"
                    label="Ingrese la razón social"
                    v-model="business.tinName"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    rounded
                    outlined
                    type="text"
                    :rules="[rules.required]"
                    label="Persona de contacto"
                    v-model="business.contactName"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    rounded
                    outlined
                    :rules="[rules.required]"
                    label="Número de la persona de contacto"
                    v-model="business.phone"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-textarea
                    v-model="business.zone"
                    label="Dirección exacta del negocio"
                    rounded
                    outlined
                    :rules="[rules.required]"
                    rows="3"
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="6">
                  <v-textarea
                    label="Descripción corta del negocio"
                    v-model="business.description"
                    :rules="[rules.required]"
                    rounded
                    outlined
                    rows="3"
                  ></v-textarea>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    outlined
                    rounded
                    type="text"
                    label="Título sección envío gratis"
                    v-model="business.freeShippingSectionTitle"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    outlined
                    rounded
                    label="Subtítulo sección envío gratis"
                    type="text"
                    v-model="business.freeShippingSectionSubtitle"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    type="text"
                    rounded
                    outlined
                    label="Ingrese el eslogan de la empresa"
                    v-model="business.slogan"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    type="text"
                    rounded
                    outlined
                    label="Ingrese el correo electrónico de la empresa"
                    v-model="business.email"
                  />
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                    v-model="business.cities"
                    :items="cities"
                    item-value="value"
                    multiple
                    label="Ciudad"
                    item-text="text"
                    outlined
                    :rules="[rules.array]"
                    rounded
                  >
                    <template
                      v-slot:selection="{ attrs, item, select, selected }"
                    >
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        @click="select"
                      >
                        <strong>{{ item.text }}</strong>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                    v-model="business.ochoCategories"
                    :items="getCategories"
                    multiple
                    outlined
                    label="Categoría"
                    item-value="value"
                    item-text="fullName"
                    chips
                    :rules="[rules.array]"
                    rounded
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                    v-model="business.tags"
                    :items="getTags"
                    multiple
                    outlined
                    chips
                    label="Tags"
                    rounded
                    item-value="value"
                    item-text="text"
                    style="min-height: 45px"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-combobox
                    v-model="business.keywords"
                    :search-input.sync="comboboxSearch"
                    hide-selected
                    label="Palabras clave para la busqueda"
                    multiple
                    rounded
                    outlined
                    chips
                    deletable-chips
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Enter <kbd>enter</kbd> pagar agregar
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    type="number"
                    rounded
                    outlined
                    @keypress="isNumber($event)"
                    label="Latitud"
                    v-model.number="address[0]"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :rules="[rules.required]"
                    type="number"
                    rounded
                    outlined
                    @keypress="isNumber($event)"
                    label="Longitud"
                    v-model.number="address[1]"
                  />
                </v-col>
                <v-col
                  v-if="categories.length > 0"
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                >
                  <l-map
                    @update:center="centerUpdated"
                    class="fill-height"
                    style="height: 50vh"
                    :zoom="zoom"
                    :center="address"
                  >
                    <l-tile-layer :url="url"></l-tile-layer>
                    <l-marker
                      @update:lat-lng="positionUpdated"
                      :draggable="true"
                      :lat-lng="address"
                      :icon="getImage"
                    >
                      <l-tooltip>
                        Mueva el marcador a la ubicación del comercio
                      </l-tooltip>
                    </l-marker>
                    <v-geosearch :options="geosearchOptions"></v-geosearch>
                  </l-map>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    @keypress="isNumber($event, business.discount)"
                    type="number"
                    label="Comisión"
                    rounded
                    outlined
                    v-model.number="business.discount"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="business.deliveryType"
                    :items="deliveryType"
                    label="Seleccione tipo de delivery"
                    outlined
                    item-text="name"
                    item-value="value"
                    rounded
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="business.fleetType"
                    :items="fleetType"
                    label="Seleccione tipo de flota"
                    outlined
                    item-text="name"
                    item-value="value"
                    rounded
                  />
                </v-col>

                <!-- <v-col cols="12" md="6">
                  <v-text-field
                    rounded
                    outlined
                    type="text"
                    label="Ingrese el método de carga"
                    v-model="business.methodCharge"
                  />
                </v-col> -->

                <!-- <v-col cols="12" md="6">
                  <v-text-field
                    rounded
                    outlined
                    type="text"
                    label="Método de validación"
                    v-model="business.methodValidate"
                  />
                </v-col> -->

                <v-col cols="12" md="6">
                  <v-text-field
                    @keypress="isNumber($event, business.minimumSale)"
                    type="number"
                    label="Venta mínima"
                    rounded
                    outlined
                    v-model.number="business.minimumSale"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="business.type"
                    :items="types"
                    label="Seleccione tipo de pantalla"
                    outlined
                    item-text="name"
                    item-value="value"
                    rounded
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="business.timeZone"
                    :items="timeZone"
                    label="Seleccione la zona horaria"
                    outlined
                    rounded
                  />
                </v-col>
                <v-row v-if="extraFees.length > 0" class="pa-0 ma-0">
                  <v-col cols="12">
                    <h3>Tarifas adicionales</h3>
                    <v-divider class="mb-5"></v-divider>
                  </v-col>

                  <v-row v-for="(fee, index) of extraFees" :key="index">
                    <v-col cols="12" md="3">
                      <v-autocomplete
                        v-model="fee.extraFeeIsFixed"
                        :items="extraFeeIsFixed"
                        label="Seleccione tipo de recargo"
                        outlined
                        rounded
                      />
                    </v-col>

                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        type="text"
                        rounded
                        outlined
                        label="Nombre del recargo"
                        v-model="fee.extraFeeName"
                      />
                    </v-col>

                    <v-col cols="12" md="2">
                      <v-text-field
                        @keypress="isNumber($event, fee.extraFeeSum)"
                        type="number"
                        rounded
                        outlined
                        label="Valor del recargo"
                        v-model.number="fee.extraFeeSum"
                      />
                    </v-col>
                    <v-col
                      style="display: flex; align-items: flex-start"
                      sm="6"
                      md="2"
                    >
                      <v-switch v-model="fee.active" label="Activa"></v-switch>
                    </v-col>
                    <v-col
                      style="display: flex; align-items: center"
                      sm="6"
                      md="2"
                    >
                      <v-btn
                        @click="removeFee(index)"
                        small
                        color="red darken-4"
                        class="white--text mb-4"
                        >Eliminar</v-btn
                      >
                    </v-col>
                  </v-row>

                  <v-col cols="12" sm="12" md="12">
                    <a @click="addFees()">Agregar tarifa</a>
                  </v-col>

                  <v-col class="mt-5" cols="12">
                    <h3>Configuración de KM</h3>
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      @keypress="isNumber($event)"
                      type="number"
                      rounded
                      outlined
                      label="Ingrese el costo por Km recorrido"
                      v-model.number="business.kmConfiguration.dayCostKm"
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      @keypress="isNumber($event)"
                      type="number"
                      outlined
                      rounded
                      label="Ingrese el Costo por recoger"
                      v-model.number="business.kmConfiguration.dropoffDay"
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      @keypress="isNumber($event)"
                      type="number"
                      rounded
                      outlined
                      label="Ingrese el costo por entregar"
                      v-model.number="business.kmConfiguration.pickupDay"
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      @keypress="isNumber($event)"
                      type="number"
                      rounded
                      outlined
                      label="Ingrese el cargo extra por KM"
                      v-model.number="business.kmConfiguration.extraKmCharge"
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-autocomplete
                      v-model="business.priceRange"
                      :items="priceRange"
                      label="Precio"
                      outlined
                      rounded
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      type="number"
                      rounded
                      outlined
                      @keypress="isNumber($event)"
                      label="Ingrese el tiempo promedio"
                      v-model.number="business.processTime"
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      type="number"
                      rounded
                      outlined
                      @keypress="isNumber($event)"
                      label="Ingrese el costo por empaque"
                      v-model.number="business.takeOutCost"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.active"
                      label="Activo"
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.adults"
                      label="Adultos"
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.closed"
                      label="Cerrado"
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.featured"
                      label="Destacado"
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.isComingSoon"
                      label="Próximamente"
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.isForceClosed"
                      label="Cerrado forzado"
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.isOpen"
                      label="Abierto"
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.isTestMode"
                      label="Modo tester"
                    ></v-switch>
                  </v-col>

                  <!-- <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.isWarehouse"
                      label="Es almacén"
                    ></v-switch>
                  </v-col> -->

                  <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.kmApply"
                      label="Configuración de kilometros"
                    ></v-switch>
                  </v-col>

                  <!-- <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.manageEvents"
                      label="Administra eventos"
                    ></v-switch>
                  </v-col> -->

                  <!-- <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.manageInventory"
                      label="Administra inventarios"
                    ></v-switch>
                  </v-col> -->

                  <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.ochoActive"
                      label="Activo por ocho"
                    ></v-switch>
                  </v-col>

                  <!-- <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.standAlone"
                      label="Independiente"
                    ></v-switch>
                  </v-col> -->

                  <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.switchLayout"
                      label="Layout vertical"
                    ></v-switch>
                  </v-col>

                  <!-- <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.reservations"
                      label="Reservaciones"
                    ></v-switch>
                  </v-col> -->

                  <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.ochoCategoryKm"
                      label="Ocho categorías Km"
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.ochoVerified"
                      label="Verificado por ocho"
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-switch
                      v-model="business.isCongested"
                      label="Comercio congestionado"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-row class="pa-0 ma-0">
        <v-col cols="12">
          <v-divider></v-divider>
          <v-row justify="end">
            <v-btn
              v-if="operationType == 'new'"
              @click="newBusiness"
              class="save-btn"
              color="primary"
              dark
              >Guardar</v-btn
            >
            <v-btn
              v-else
              @click="cloneBusiness"
              class="save-btn"
              color="primary"
              dark
              >Clonar comercio</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
    </v-card-actions>
    <input
      class="upload"
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    />
  </v-card>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

import { LMap, LTileLayer, LIcon, LMarker, LTooltip } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

import { OpenStreetMapProvider } from "leaflet-geosearch";
import VGeosearch from "vue2-leaflet-geosearch";
import axios from "axios";

export default {
  name: "new-business",
  props: ["operationType", "businessOriginal"],
  components: {
    lottie: Lottie,
    LMap,
    LTileLayer,
    LIcon,
    LMarker,
    VGeosearch,
    LTooltip,
  },
  data() {
    return {
      comboboxSearch: null,
      snackbarColor: "red",
      rules: {
        required: (value) => !!value || "Obligatorio",
        array: (value) => (value && value.length) || "Obligatorio",

        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
        email: (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Correo Invalído";
        },
      },
      geosearchOptions: {
        showMarker: false,
        provider: new OpenStreetMapProvider(),
        animateZoom: true,
        autoClose: true,
        searchLabel: "Buscar comercio",
      },
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 27,
      center: [47.41322, -1.219482],
      priceRange: [1, 2, 3, 4],
      business: {
        active: false,
        adults: false,
        bankAccounts: [
          {
            bank: "",
            currency: "",
            name: "",
            number: "",
            type: "",
          },
        ],
        beforeTax: true,
        city: "",
        cities: [],
        closed: true,
        contactName: null,
        cover: {
          loading: "",
          original: "",
        },
        createdAt: "",
        createdBy: "",
        currentPercentage: 0.15,
        deleted: false,
        deletedAt: "",
        deletedBy: "",
        deliveryType: "regular",
        description: "",
        discount: 0.15,
        dispatchTime: 0,
        elasticType: "up",
        expectedWeeklySales: 500,
        featured: false,
        freeShippingMinimum: 0,
        geoAddress: [],
        isComingSoon: true,
        isForceClosed: false,
        isOpen: false,
        isCongested: false,
        isTestMode: false,
        kmApply: true,
        kmConfiguration: {
          dayCostKm: 5,
          dayRange: 8,
          dayStarts: 9,
          dropoffDay: 20,
          dropoffNight: 25,
          nightCostKm: 5,
          nightRange: 6,
          nightStarts: 18,
          pickupDay: 25,
          pickupNight: 25,
          extraKmCharge: 5,
          maxDistance: 12,
        },
        logo: {
          loading: "",
          original: "",
        },
        longName: "",
        manageEvents: false,
        manageInventory: false,
        minimumSale: 0,
        ochoActive: true,
        ochoCategories: [],
        ochoCategoryKm: false,
        ochoVerified: true,
        openTable: false,
        openTablePolicy: "",
        overhead: 0,
        paymentMethods: ["transfer", "deposit", "cash"],
        percentageLimit: 16,
        percentageStep: 1,
        phone: "",
        pickupActive: true,
        pins: [1234],
        priceRange: 4,
        processTime: 20,
        rating: 0,
        reservations: false,
        schedule: {
          monday: [
            {
              opensAt: "00:00:00",
              closesAt: "23:30:00",
            },
          ],
          tuesday: [
            {
              opensAt: "00:00:00",
              closesAt: "23:30:00",
            },
          ],
          wednesday: [
            {
              opensAt: "00:00:00",
              closesAt: "23:30:00",
            },
          ],
          thursday: [
            {
              opensAt: "00:00:00",
              closesAt: "23:30:00",
            },
          ],
          friday: [
            {
              opensAt: "00:00:00",
              closesAt: "23:30:00",
            },
          ],
          saturday: [
            {
              opensAt: "00:00:00",
              closesAt: "23:30:00",
            },
          ],
          sunday: [
            {
              opensAt: "00:00:00",
              closesAt: "23:30:00",
            },
          ],
        },
        shortName: "",
        slogan: "",
        freeShippingSectionTitle: "",
        freeShippingSectionSubtitle: "",
        standAlone: false,
        switchLayout: true,
        tagLine: "",
        tags: [],
        keywords: [],
        takeOutCost: 0,
        taxed: true,
        tin: "",
        webName: "",
        zone: "",
      },
      snackbar: false,
      snackbarText: null,
      modalUploadImage: false,
      loading: true,
      animationSpeed: 1.1,
      categories: [],
      timeZone: [
        "-1200",
        "-1100",
        "-1000",
        "-900",
        "-800",
        "-700",
        "-600",
        "-500",
        "-400",
        "-300",
        "-200",
        "-100",
        "0",
        "100",
        "200",
        "300",
        "400",
        "500",
        "600",
        "700",
        "800",
        "900",
        "1000",
        "1100",
        "1200",
      ],
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      saving: false,
      imageType: null,
      suscriptionBusiness: null,
      allTags: [],
      imgSrc: null,
      imgSrcBanner: "",
      imgSrcBrand: "",
      imageFile: null,
      cities: [],
      address: ["14.0985252", "-87.1789062"],
      imageBannerFile: null,
      imageBrandFile: null,
      deliveryType: [
        { name: "Regular", value: "regular" },
        { name: "Recogida", value: "pickUp" },
        { name: "Recogida regular", value: "regularPickUp" },
        { name: "Ninguno", value: "none" },
      ],
      fleetType: [
        { name: "Ocho", value: "ocho" },
        { name: "Mixto", value: "mixed" },
        { name: "Propia", value: "own" },
      ],
      types: [
        { name: "Airtime", value: "airTime" },
        { name: "ATM", value: "atm" },
        { name: "Wallet", value: "wallet" },
        { name: "Store", value: "store" },
      ],
      extraFeeIsFixed: [
        { text: "Porcentual", value: false },
        { text: "Fijo", value: true },
      ],
      extraFees: [
        {
          extraFeeIsFixed: "",
          extraFeeName: "",
          extraFeeSum: "",
          active: false,
        },
      ],
    };
  },
  watch: {
    "business.ochoCategories"() {
      if (this.business && this.business.tags) {
        this.business.tags = this.business.tags.filter((item) =>
          this.getTags.includes(item)
        );
      }
    },
    "business.cities"() {
      let filteredCategories = [];
      this.categories.forEach((category) => {
        if (
          this.business.ochoCategories.includes(category.value) &&
          this.business.cities.includes(category.city)
        ) {
          filteredCategories.push(category.value);
        }
      });
      this.business.ochoCategories = filteredCategories;
    },
  },
  computed: {
    getCategories() {
      let categories = this.categories.filter(
        (item) =>
          this.business.cities && this.business.cities.includes(item.city)
      );
      return categories
        .map((category) => {
          let newCategory = category;
          let city = this.cities.find((city) => city.value === category.city);
          newCategory.fullName = city ? `${city.text} - ${category.text}` : "";
          return newCategory;
        })
        .sort(this.compareByFullName);
    },
    getUserToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
    getTags() {
      let tags = [];
      this.business.ochoCategories.forEach((cat) => {
        let result = this.categories.find((item) => item.value == cat);
        tags = tags.concat(result ? result.tags : []);
      });

      return tags.sort(this.compareArray);
    },
    getSectionToken() {
      return new Promise((resolve, reject) => {
        fb.auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            resolve(token);
          });
      });
    },
    // validateBusiness() {
    //   let controls = [
    //     {
    //       name: "Nombre del comercio",
    //       control: "shortName",
    //     },
    //     {
    //       name: "Dirección",
    //       control: "zone",
    //     },
    //     {
    //       name: "Descripción",
    //       control: "description",
    //     },
    //     {
    //       name: "Categorias",
    //       control: "ochoCategories",
    //     },
    //     {
    //       name: "Ciudad",
    //       control: "cities",
    //     },
    //   ];

    //   for (let index = 0; index < controls.length; index++) {
    //     if (!this.business[controls[index].control]) {
    //       this.handleError(controls[index].name);
    //       return;
    //     }
    //   }

    //   if (!this.address[0] || !this.address[0]) {
    //     this.snackbarText = `Ubicación inválida`;
    //     this.snackbar = true;
    //     return false;
    //   }

    //   if (this.business.ochoCategories.length == 0) {
    //     this.snackbarText = `Debe seleccionar una categoría válida.`;
    //     this.snackbar = true;
    //     return false;
    //   }
    //   return true;
    // },

    getImage() {
      return L.icon({
        iconUrl: require("../../../assets/user.svg"),
        shadowUrl: require("../../../assets/map-marker.png"),
        iconSize: [32, 32],
        shadowSize: [64, 64],
        iconAnchor: [-12, 56],
        shadowAnchor: [4, 62],
        popupAnchor: [-3, -76],
      });
    },
  },
  methods: {
    handleError(control) {
      this.snackbarText = `El campo ${control} es obligatorio`;
      this.snackbar = true;
    },
    positionUpdated(e) {
      this.address = [e.lat, e.lng];
    },
    addFees() {
      this.extraFees.push({
        extraFeeIsFixed: "",
        extraFeeName: "",
        extraFeeSum: "",
        active: false,
      });
    },
    removeFee(index) {
      this.extraFees = this.extraFees.filter((item, i) => i != index);

      if (this.extraFees.length == 0) {
        this.extraFees = [
          {
            extraFeeIsFixed: "",
            extraFeeName: "",
            extraFeeSum: "",
            active: false,
          },
        ];
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    centerUpdated(e) {
      this.address = [e.lat, e.lng];
    },
    setImage(e) {
      this.imageFile = e.target.files[0];

      if (this.imageType == "banner") {
        this.imageBannerFile = e.target.files[0];
      } else if (this.imageType == "brand") {
        this.imageBrandFile = e.target.files[0];
      }

      if (e.target.files[0].type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (this.imageType == "banner") {
            this.imgSrcBanner = event.target.result;
          } else {
            this.imgSrcBrand = event.target.result;
          }
          this.modalUploadImage = true;
        };

        reader.readAsDataURL(this.imageFile);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    successUpload() {
      this.modalUploadImage = false;
      this.save = false;
      this.snackbar = true;
      this.snackbarText = "Imágen actualizada";
    },
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    async cloneBusiness() {
      let token = await this.getUserToken;

      if (this.$refs.form.validate()) {
        this.saving = true;
        try {
          let businessCloneResult = await this.cloneBusinessAddonsAndProducts();
          this.business.longName = this.business.shortName;
          this.business.createdBy = this.$store.state.user[".key"];
          this.business.createdAt = new Date();
          this.business.extraFees = this.extraFees;
          delete this.business[".key"];
          db.collection("businesses")
            .doc(businessCloneResult.data.newBusinessId)
            .update(this.business)
            .then(async (res) => {
              this.cloneProcess = "Actualizando comercio...";
              await this.getGeoPoint(
                token,
                businessCloneResult.data.newBusinessId
              );
              await this.updatePictures(businessCloneResult.data.newBusinessId);
              this.saving = false;
              this.$emit("success");
            });
        } catch (error) {
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
          this.saving = false;
        }
      } else {
        this.snackbarText = "Ingrese los campos obligatorios.";
        this.snackbar = true;
        this.snackbarColor = "red";

        this.$refs.top.scrollIntoView();
      }
    },
    async cloneBusinessAddonsAndProducts() {
      this.cloneProcess = "Copiando productos y complementos...";
      let token = await this.getUserToken;
      return new Promise((resolve, reject) => {
        let data = {
          businessId: this.businessOriginal[".key"],
        };
        axios
          .post(
            `${process.env.VUE_APP_FUNCTIONS_URL}/httpBusinessesCloneV2`,
            data,
            { headers: { Authorization: "Basic " + token } }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    selectImage(imageType) {
      this.imageType = imageType;
      this.$refs.input.click();
    },

    showFileChooser() {
      this.$refs.input.click();
    },

    getGeoPoint(token, businessId) {
      return new Promise((resolve, reject) => {
        let data = {
          lat: this.address[0],
          lng: this.address[1],
          businessId: businessId,
        };

        var httpBusinessesUpdateGeoPoint = fb
          .functions()
          .httpsCallable("httpBusinessesUpdateGeoPoint");
        httpBusinessesUpdateGeoPoint(data)
          .then((result) => {
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async newBusiness() {
      let token = await this.getSectionToken;

      if (this.$refs.form.validate()) {
        this.saving = true;
        this.business.longName = this.business.shortName;
        this.business.createdBy = this.$store.state.user[".key"];
        this.business.createdAt = new Date();
        this.business.extraFees = this.extraFees;
        this.business.kmConfiguration.dayCostKm = this.business.kmConfiguration
          .dayCostKm
          ? this.business.kmConfiguration.dayCostKm
          : 0;
        this.business.kmConfiguration.dropoffDay = this.business.kmConfiguration
          .dropoffDay
          ? this.business.kmConfiguration.dropoffDay
          : 0;
        this.business.kmConfiguration.pickupDay = this.business.kmConfiguration
          .pickupDay
          ? this.business.kmConfiguration.pickupDay
          : 0;

        db.collection("businesses")
          .add(this.business)
          .then(async (ref) => {
            await this.getGeoPoint(token, ref.id);
            await this.updatePictures(ref.id);
            this.saving = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.snackbarText = err.message;
            this.snackbar = true;
            this.snackbarColor = "red";
          });
      } else {
        this.snackbarText = "Ingrese los campos obligatorios.";
        this.snackbar = true;
        this.snackbarColor = "red";
        this.$refs.top.scrollIntoView();
      }
    },

    updatePictures(businessId) {
      return new Promise(async (resolve, reject) => {
        let bucket = `gs://${process.env.VUE_APP_PROJECT_ID}-businesses`;
        if (bucket) {
          let uploadBrand = new Promise((resolve, reject) => {
            if (this.imageBrandFile) {
              fb.app()
                .storage(bucket)
                .ref()
                .child(`${businessId}/brand`)
                .put(this.imageBrandFile)
                .then((res) => {
                  resolve(true);
                });
            } else {
              resolve(true);
            }
          });
          await uploadBrand;

          let uploadBanner = new Promise((resolve, reject) => {
            if (this.imageBannerFile) {
              fb.app()
                .storage(bucket)
                .ref()
                .child(`${businessId}/banner`)
                .put(this.imageBannerFile)
                .then((res) => {
                  resolve(true);
                });
            } else {
              resolve(true);
            }
          });

          await uploadBanner;
          resolve("success");
        }
      });
    },
    compare(a, b) {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    },
    compareArray(a, b) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    },
    compareByFullName(a, b) {
      if (a.fullName < b.fullName) return -1;
      if (a.fullName > b.fullName) return 1;
      return 0;
    },
  },
  async mounted() {
    let scrollPosition = document.getElementsByClassName("v-dialog")[0];
    if (scrollPosition) {
      scrollPosition.scrollTop = 0;
    }

    await db
      .collection("cities")
      .where("deleted", "==", false)
      .get()
      .then((response) => {
        response.forEach((item) => {
          let city = {
            value: item.id,
            text: item.data().name,
          };
          this.cities.push(city);
        });
      });

    await db
      .collection("categories")
      .where("deleted", "==", false)
      .get()
      .then(async (response) => {
        response.forEach(async (item) => {
          let category = {
            value: item.id,
            text: item.data().name,
            city: item.data().city,
            tags: item.data() && item.data().tags ? item.data().tags : [],
          };

          let tags = await db
            .collection("categories")
            .doc(item.id)
            .collection("tags")
            .where("deleted", "==", false)
            .get();
          category.tags = tags.docs.map((item) => {
            return {
              text: item.data().name,
              value: item.id,
            };
          });
          this.categories.push(category);
        });
      });

    if (this.operationType == "clone") {
      this.business = Object.assign({}, this.businessOriginal);
      this.address = [
        this.business.geoAddress.geopoint.latitude,
        this.business.geoAddress.geopoint.longitude,
      ];

      if (this.business.extraFees && this.business.extraFees.length > 0)
        this.extraFees = Object.assign([], this.business.extraFees);
      else this.business.extraFees = this.extraFees;

      if (!this.business.hasOwnProperty("isTestMode"))
        this.business.isTestMode = false;
    }
  },
};
</script>

<style scoped>
.logo,
.cover {
  margin-top: 20px;
  cursor: pointer;
}
.save-btn {
  margin-top: 20px;
}
.add-tag {
  background-color: rgba(0, 0, 0, 0.37);
}

.add-tag-ico {
  font-size: 25px !important;
  cursor: pointer;
  margin-left: 5px;
}

.btn-modal-add-tags {
  justify-content: center;
}

.upload-container .logo {
  width: 10%;
  background-color: rgba(255, 255, 255, 0.643);
  border: 1px solid gray;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  margin-top: -8%;
  margin-left: 20px;
}

.logo-up {
  background-color: rgba(255, 255, 255, 0.643);
  border: 1px solid gray;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  margin-top: -100px;
  margin-left: 20px;
  cursor: pointer;
}

.upload-container .banner {
  border: 1px solid black;
  border-radius: 10px;
  max-width: 100%;
  max-height: 250px;
  object-fit: cover;
}

.upload {
  display: none;
}
.cover-up {
  border: 1px solid black;
  border-radius: 10px;
}

.logo-up:hover {
  border: 2px dashed #f06f3d;
  cursor: pointer;
}

.cover-up:hover {
  border: 1px dashed #f06f3d;
  cursor: pointer;
}

.add-image {
  font-size: 30px;
  color: rgba(128, 128, 128, 0.733) !important;
  cursor: pointer;
}
.img-tag {
  background-color: rgba(231, 231, 230, 0.808) !important;
  justify-content: center;
  font-weight: bold;
}

.preview-image {
  max-width: 540px;
  min-width: 540px;
  max-height: 540px;
}

.leaflet-interactive {
  cursor: move !important;
}
</style>
