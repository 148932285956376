<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{snackbarText}}</v-snackbar>
    <v-overlay class="loading-center" :value="saving">
        <lottie
            :options="defaultOptions"
            :height="300"
            :width="400"
            v-on:animCreated="handleAnimation"
        />
    </v-overlay>

    <v-card>
        <v-card-title class="grid-close">
            <span class="headline">Comercios de {{businessFather.shortName}}</span>
            <v-btn
                style="right:10px; top:10px;"
                icon
                color="primary"
                fab
                absolute
                @click="$emit('cancel')"
                >
                <v-icon dark>fa-times</v-icon>
            </v-btn>
        </v-card-title>

      <v-card-text>
        <businesses-list-skeleton v-if="loading"/>
        <v-container v-if="!loading" fluid>
          <v-row>
              <v-col cols="12" sm="12" md="12">
                <a-transfer
                    v-if="businesses"
                    :listStyle="{
                        'height': '420px!important',
                         'width': '45%',
                      
                        'background' : 'white'
                    }"
                    :data-source="getBusinesses"
                    :titles="['Todos los comercios', 'Comercios agregados']"
                    :target-keys="selectedBusinesses"
                    :selected-keys="selectedKeys"
                    :show-search="true"
                    @search="handleSearch"
                    :render="item => item.title"
                    :disabled="disabled"
                    :filter-option="filterOption"
                    @change="handleChange"
                    @selectChange="handleSelectChange"
                    @scroll="handleScroll"
                    :locale= "{ itemUnit: '', itemsUnit: '', notFoundContent: 'Lista vacía', searchPlaceholder: 'Buscar comercio' }"
                  />
              </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions v-if="!loading">
        <v-container fluid >
            <v-divider></v-divider>
              <v-row class="pa-0 ma-0">
                <v-col cols="12" xs="12" sm="12" md="12">
                    <v-row  align="center" justify="end">
                        <v-btn @click="updateChildren" class="save-btn" color="primary">Guardar</v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
      </v-card-actions>
  </v-card>
</div>

</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie"
import * as animationData from "../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from 'vuex';
import aTransfer from 'ant-design-vue/lib/transfer';
import businessesListSkeleton from '../skeleton/businesses-list-skeleton'

export default {
  name: "edit-businesses-children",
  props: ['businessFather', 'businesses'],
  components: {
    lottie: Lottie,
    aTransfer,
    businessesListSkeleton
  },
  data() {
   return {
    animationSpeed: 1.1,
    defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true
      },

    snackbar : false,
    snackbarText : '',
    saving : false,
    selectedBusinesses : [],
    loading : true,
    selectedKeys: [],
    disabled: false,

    }
  },
  computed : {
     getBusinesses(){
        return this.businesses.map(item => {
            return {
                key: item['.key'],
                title: item.shortName,
            }
        })    
     }
  },
  mounted(){
    this.selectedBusinesses = this.businesses.filter( item => item.isChildOf == this.businessFather[".key"])
    this.selectedBusinesses = this.selectedBusinesses.map( item => {
        return item[".key"]
      })
    this.loading = false
  },
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    
    handleChange(nextTargetKeys, direction, moveKeys) {
      this.selectedBusinesses = nextTargetKeys;
    },
    handleSearch(dir, value) {
      return value.toUpperCase();
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
    filterOption(inputValue, option) {
      return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    },
    handleScroll(direction, e) {
     
    },
    handleDisable(disabled) {
      this.disabled = disabled;
    },
    updateChildren() {
      this.saving = true
      this.businesses.forEach(item => {
         if (item.isChildOf == this.businessFather[".key"] &&  !this.selectedBusinesses.includes(item[".key"]) ) {           
            db.collection('businesses').doc(item[".key"]).update({
              isChildOf: ''
            })
         }
      })

      if ( this.selectedBusinesses.length == 0 ) {
          this.saving = false
          this.$emit('success')
      }

      this.selectedBusinesses.forEach( (business, index) => {          
          db.collection('businesses').doc(business).update({
              isChildOf: this.businessFather['.key']
          })
          .then( resp => {
            if ( index <= this.selectedBusinesses.length -1) {
              this.saving = false
              this.$emit('success')
            }
          })
          .catch( err => {          
              this.saving = false
              this.snackbarText = 'Ocurrió un error inesperado, inténtelo nuevamente.'
              this.snackbar = true
          })
      })
    }
  }
}
</script>
